<template>
  <div id="mbti-result" class="section">
    <div class="jumbotron text-right">
      <div class="container">
        <h2>Tipe kepribadianmu adalah</h2>
        <h1>ISFJ</h1>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <p>
            Seseorang yang berkepribadian ISFJ sering disebut the nurturers atau pengasuh.  Ada banyak pribadi yang termasuk ISFJ yakni 9-14% populasi di dunia. Dimana menurut dimensinya ISFJberarti Introversion atau seseorang yang tidak suka akan hubungan dan dunia luar. Sensing yaitu mereka yang mengenal hal-hal secara konkret atau nyata.  Feeling yaitu mereka memutuskan berbagai hal dengan perasaan dan menyangkut pribadi serta Judging yang mana mereka mengutamakan rencana baru melakukan hal selanjutnya. 
          </p>
          <p>
            Ciri kepribadian ISFJ adalah:
            <ul>
              <li> Stabil, prakti dan bersahaja karena mereka tidak suka bekerja dengan pemikiran yang abstrak.</li>
              <li> Sangat jeli dan memahami perasaan orang lain.</li>
              <li> Seseorang yang kaya akan informasi dan juga bekerja keras dalam waktu yang lama untuk mendapatkan hasil yang diinginkan. </li>
              <li>Memegang nilai keamanan dan juga tradisi hidup secara damai.</li>
            </ul>
          </p>
          <p>Saran dan pekerjaan yang cocok:
            <ul>
              <li>
                Belajar mengatakan tidak jika tidak suka dan cobalah untuk lebih antusias dalam melakukan berbagai hal.
              </li>
              <li>
                Jangan terjebak zona nyaman dan cobalah hal lainnya.
              </li>
              <li>
                Asisten administrasi, desainer, perawat, pemimpin usaha, pemilik toko, pembantu pengacara, dan juga pengelola atau manajer.
              </li>
            </ul>
          </p>
          <p>
            Asmara untuk ISFJ adalah mereka yang memiliki kepribadian ESFP atau ESTP yang mungkin lebih bisa mengimbangi pribadi ISFJ.
          </p>
        </div>
        <div class="col-lg-5">
          <img src="../../../assets/images/mbti/ISFJ.png" alt="" class="illustration">
          <div class="media">
            <img src="../../../assets/images/mbti/Introversion.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="introversion">Introversion</h5>
              <p>cenderung pendiam dan suka menyendiri</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Sensing.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="sensing">Sensing</h5>
              <p>lebih fokus terhadap gambaran masa depan</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Feeling.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="feeling">Feeling</h5>
              <p>membuat keputusan berdasarkan logika</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Judgement.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="judgement">Judgement</h5>
              <p>cenderung membiarkan pilihan tetap terbuka</p>
            </div>
          </div>
        </div>
      </div>
      <ShareButton :result="result" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ISFJ",
  components: {
    ShareButton: () => import('@/components/ShareButton'),
  },
  data() {
    return {
      result: {
        name: 'ISFJ',
        slug: 'isfj'
      }
    }
  }
};
</script>

<style scoped>

</style>
